import React from "react";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
const callToActionData = {
  title: "Ready to start your technology transformation journey with us?",
  subtitle:
    "We are here to help! Our team of experienced technology consultants is ready to work closely with you to understand your business needs and provide customized solutions that will drive growth and success.",
  subtitle2:
    "Whether you need to develop a technology strategy, implement new software, or optimize your data analytics capabilities, we have the expertise to help you achieve your goals. Contact us today to learn more about our services and how we can help you take your business to the next level.",
  btnText: "Contact Us Now!",
};

const CalltoActionEight = () => {
  return (
    <div className="rn-callto-action rn-call-to-action style-8 content-wrapper">
      <div className="container">
        <div className="row row--0 align-items-center ">
          <div className="col-lg-12">
            <div className="inner">
              <div className="content text-center">
                <h2 className="title">{callToActionData.title}</h2>
                <h6 className="subtitle">{callToActionData.subtitle}</h6>
                <br></br>
                <h6 className="subtitle">{callToActionData.subtitle2}</h6>
                <div className="call-to-btn text-center mt--30">
                  <Link className="btn-default btn-icon" to="/contact">
                    {callToActionData.btnText}{" "}
                    <i className="icon">
                      <FiArrowRight />
                    </i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionEight;
