import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";

const CopyrightTwo = () => {
    return (
        <div className="copyright-area copyright-style-one variation-two">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-7 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                <li><Link to="#">Privacy Policy</Link></li>
                                <li><Link to="#">Terms And Condition</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5 mt_sm--20">
                        <div className="copyright-center text-center">
                            <ul className="social-icon social-default color-lessdark justify-content-center">
                                <li><Link to={{ pathname: "https://www.facebook.com/jacgroupintl"}} target='_blank'><FiFacebook /></Link></li>
                                <li><Link to={{ pathname:"https://instagram.com/jacgroupintl"}} target='_blank'><FiInstagram /></Link></li>
                                <li><Link to={{ pathname: "https://linkedin.com/company/77997693"}} target='_blank'><FiLinkedin /></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
                        <div className="copyright-right text-center text-lg-right">
                            <p className="copyright-text">Copyright © {new Date().getFullYear()} Jamal Ahmad Consulting Group</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CopyrightTwo;