import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const BrandList = [
    {
        image: './images/brand/VZ-gray.png',
        link: 'https://verozone.md'
    },
    {
        image: './images/brand/DOORS-gray.png'
    },
    {
        image: './images/brand/HALA-gray.png'
    },
    {
        image: './images/brand/ITI-gray.png'
    },
    {
        image: './images/brand/VA-gray.png'
    },
   /* {
        image: './images/brand/brand-06.png'
    },
    {
        image: './images/brand/brand-07.png'
    },
    {
        image: './images/brand/brand-08.png'
    },
    {
        image: './images/brand/brand-01.png'
    },*/
  
]

const BrandThree = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <Link to={{pathname: data.link}} target='_blank'><img src={`${data.image}`} alt="Brand Image" /></Link>
                </li>
            ))}
        </ul>
    )
}

export default BrandThree;
