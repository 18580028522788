import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";

const dataList = [
  {
    image: "/images/services/Techroad.png",
    title: "Technology Roadmap Development",
    description:
      "We work with you to develop a comprehensive technology roadmap that aligns your IT investments with your long-term business objectives.",
  },
  {
    image: "/images/services/BPA.png",
    title: "Business Process Optimization",
    description:
      "We analyze and optimize your business processes to improve efficiency, reduce costs, and increase overall effectiveness.",
  },
];

const dataList2 = [
  {
    image: "/images/services/SD.png",
    title: "Software Development",
    description:
      "We specialize in developing custom software solutions tailored to your business needs, using the latest technologies and best practices.",
  },
  {
    image: "/images/services/CC.png",
    title: "Cloud Computing",
    description:
      "The internet-based on-demand delivery of computing resources, providing greater flexibility, cost-effectiveness, and scalability.",
  },
];

const dataList3 = [
  {
    image: "/images/services/ECOM.png",
    title: "E-Commerce SaaS",
    description:
      "Our SaaS e-commerce solution is a cloud-based platform that enables businesses to build and operate online stores, manage product catalogs, process transactions, and automate various aspects of their sales and marketing operations, all in a seamless and user-friendly manner.",
  },
  {
    image: "/images/services/ERP.png",
    title: "Enterprise Resource Planning (ERP)",
    description:
      "Our ERP system is a comprehensive solution that integrates all core business processes and data into a single, unified system, providing real-time visibility and control over operations, optimizing resource utilization, improving decision-making, and enhancing efficiency across the organization.",
  },
  {
    image: "/images/services/EDU.png",
    title: "Educational Management System",
    description:
      "Our Academy/University Management System is a comprehensive solution designed to manage and automate all aspects of the academic institution, including admissions, student information, course management, staff payroll, attendance tracking, and reporting, enabling streamlined operations, enhanced efficiency, and improved academic outcomes.",
  },
];

const TabThree = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-12">
                <div className="rn-default-tab style-three">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Strategy</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Services</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Solutions</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-4">
                              <div className="section-title">
                                <h4 className="title">How can we help you?</h4>
                                <p>
                                  We provide customized technology consulting
                                  services to help your business thrive in the
                                  digital age.
                                </p>
                                <div className="read-more">
                                  <Link
                                    className="btn-default btn-small"
                                    to="/about-us"
                                  >
                                    Read More
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                {dataList.map((val, i) => (
                                  <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    key={i}
                                  >
                                    <div className="card-box card-style-1 text-start">
                                      <div className="inner">
                                        <div className="image">
                                          <Link to="#service">
                                            <img
                                              src={`${val.image}`}
                                              alt="card Images"
                                            />
                                          </Link>
                                        </div>
                                        <div className="content">
                                          <h4 className="title mb--20">
                                            <Link
                                              to="#service"
                                              dangerouslySetInnerHTML={{
                                                __html: val.title,
                                              }}
                                            ></Link>
                                          </h4>
                                          <p
                                            className="description b1 color-gray mb--0"
                                            dangerouslySetInnerHTML={{
                                              __html: val.description,
                                            }}
                                          ></p>
                                          <Link
                                            className="btn-default btn-small"
                                            to="#service"
                                          >
                                            Read More
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-4">
                              <div className="section-title">
                                <h4 className="title">
                                  Which services do we provide?
                                </h4>
                                <p>
                                  Some of our key services include: technology
                                  strategy development, digital transformation
                                  consulting, software implementation and
                                  integration, data analytics and business
                                  intelligence, and cloud computing advisory
                                  services
                                </p>
                                <div className="read-more">
                                  <a className="btn-default btn-small" href="#">
                                    Read More
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                {dataList2.map((val, i) => (
                                  <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    key={i}
                                  >
                                    <div className="card-box card-style-1 text-start">
                                      <div className="inner">
                                        <div className="image">
                                          <Link to="#service">
                                            <img
                                              src={`${val.image}`}
                                              alt="card Images"
                                            />
                                          </Link>
                                        </div>
                                        <div className="content">
                                          <h4 className="title mb--20">
                                            <Link
                                              to="#service"
                                              dangerouslySetInnerHTML={{
                                                __html: val.title,
                                              }}
                                            ></Link>
                                          </h4>
                                          <p
                                            className="description b1 color-gray mb--0"
                                            dangerouslySetInnerHTML={{
                                              __html: val.description,
                                            }}
                                          ></p>
                                          <Link
                                            className="btn-default btn-small"
                                            to="#service"
                                          >
                                            Read More
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-12">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                {dataList3.map((val, i) => (
                                  <div
                                    className="col-lg-4 col-md-6 col-sm-6 col-12"
                                    key={i}
                                  >
                                    <div className="card-box card-style-1 text-start">
                                      <div className="inner">
                                        <div className="image">
                                          <Link to="#service">
                                            <img
                                              src={`${val.image}`}
                                              alt="card Images"
                                            />
                                          </Link>
                                        </div>
                                        <div className="content">
                                          <h4 className="title mb--20">
                                            <Link
                                              to="#service"
                                              dangerouslySetInnerHTML={{
                                                __html: val.title,
                                              }}
                                            ></Link>
                                          </h4>
                                          <p
                                            className="description b1 color-gray mb--0"
                                            dangerouslySetInnerHTML={{
                                              __html: val.description,
                                            }}
                                          ></p>
                                          <Link
                                            className="btn-default btn-small"
                                            to="#service"
                                          >
                                            Read More
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabThree;
