import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { FiMapPin } from "react-icons/fi";

const AnyReactComponent = ({ address }) => (
  <div>
    <FiMapPin size="30px" color="#d99f0f" fill="#000000"></FiMapPin>
  </div>
);
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 33.885428,
      lng: 35.48295,
    },
    zoom: 15,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDTmr_Hs_S8DZ6d3TwfBT7pBKK94PZMi3E" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent lat={33.885428} lng={35.48295} />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapStyle;
