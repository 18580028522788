import React from "react";
import VideoTwo from "../video/VideoTwo";
import { FiCheck } from "react-icons/fi";
import { Link } from "react-router-dom";

const PopupData = [
  {
    id: "01",
    image: "./images/bg/bg-image-4.jpg",
    popupLink: [
      "https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes",
    ],
  },
];

const AboutFour = ({ image }) => {
  return (
    <div className="about-area about-style-4 rn-section-gap">
      <div className="container">
        <div className="row row--40 align-items-center">
          <div className="col-lg-6">
            <div className="thumbnail">
              <img
                className="w-100"
                src="./images/bg/ITDep.png"
                alt="About Images"
              />
            </div>
          </div>
          <div className="col-lg-6 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="inner">
                <h3 className="title">
                  JAC Group specializes in <br />{" "}
                  <strong>Technology Consultancy.</strong>
                </h3>
                <ul className="feature-list">
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">External IT Departments</h4>
                      <p className="text">
                        At JAC, we offer businesses the option to establish
                        dedicated IT units, providing a cost-effective and
                        scalable solution that enables companies to leverage
                        cutting-edge technology to drive growth, efficiency, and
                        innovation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">
                        Technical Support Around the Clock
                      </h4>
                      <p className="text">
                        JAC provides comprehensive technical support services to
                        businesses of all sizes and industries, offering 24/7
                        assistance and customized solutions to address our
                        clients' unique technology needs.
                      </p>
                    </div>
                  </li>
                </ul>
                <div className="about-btn mt--30">
                  <Link className="btn-default" to="/contact">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
