import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import { Link } from "react-router-dom";
import { FiCheck } from "react-icons/fi";
import PricingForm from "../contact/PricingForm";

const Pricing = () => {
  return (
    <>
      <SEO title="Pricing || JAC Group International" />
      <Layout>
        <BreadcrumbOne
          title="Our Pricing Model."
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Pricing"
        />
        <div className="main-content">
          <div className="row mt--40 row--15">
            <div className="col-lg-5">
              <PricingForm formStyle="contact-form-1" />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Pricing;
