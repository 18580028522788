import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";

const SlpitOne = () => {
  return (
    <div className="rn-splite-style">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-left-content">
              <img src="./images/bg/WWA1.png" alt="split Images" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">Who We Are</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  We are a group of international companies across the MENA
                  Region, UK, East Europe, and the USA specialized in Technology
                  and Consultancy. Our organization offers a wide range of
                  solutions, including technology strategy development, digital
                  transformation consulting, software implementation and
                  integration, data analytics and business intelligence, and
                  cloud computing advisory services.<br></br>
                  <br></br>With a diverse customer base across multiple regions
                  and sectors, our expertise in technology and consultancy
                  enables us to help businesses stay competitive and succeed in
                  the digital age. Our international presence allows us to serve
                  clients from various industries and regions, providing them
                  with the necessary tools to achieve their goals and thrive in
                  the ever-changing technological landscape.
                </p>
              </ScrollAnimation>

              {/* <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <ul className="split-list">
                                            <li>- Doug DeMarco, Design Prototyping Manager</li>
                                            <li>- 108 million paying subscribers</li>
                                            <li>- Over 1.7 billion hours of music played monthly</li>
                                            <li>- 4,000+ employees working across 16 offices</li>
                                        </ul>
                                    </ScrollAnimation> */}
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <div className="view-more-button mt--35">
                  <Link className="btn-default" to="/contact">
                    Contact Us
                  </Link>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlpitOne;
